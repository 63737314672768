html,
body {
  padding: 0;
  margin: 0;

  font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

* {
  box-sizing: border-box;

  &::selection {
    background: white;
    color: black;
  }
}

.background {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
